import { NextPage } from "next"
import WithPrivateRoute from "../hoc/withPrivateRoute"
import { useTranslation } from "react-i18next"
import { PageHead } from "../components"
import { TopPage } from "../containers"

const Home: NextPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageHead>{t("Top Page")}</PageHead>
      <TopPage />
    </>
  )
}

export default WithPrivateRoute(Home)
